<template>
  <b-card no-body>
    <b-card-header
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <router-link
          v-if="userProfileLink"
          :to="userProfileLink"
        >
          <b-avatar
            size="48"
            :text="userInitials(message.user)"
            :src="userProfileImage"
            class="mr-75"
          />
        </router-link>
        <b-avatar
          v-else
          size="48"
          :text="userInitials(message.user)"
          class="cursor-not-allowed mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            <router-link
              v-if="userProfileLink"
              :to="userProfileLink"
            >
              {{ getUserFullName(message) }}
            </router-link>
            <span
              v-else
              class="cursor-not-allowed"
            >
              {{ getUserFullName(message) }}
            </span>
          </h5>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span
                class="font-small-3 text-muted mr-25 d-inline-block text-truncate"
                style="max-width: 500px"
              >
                {{ `Sent to ${recipientAll}` }}
              </span>
              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">
                    Date:
                  </td>
                  <td>{{ formatDate(message.created_at) }}</td>
                </tr>
                <tr v-if="message.user">
                  <td class="text-right text-muted align-top">
                    From:
                  </td>
                  <td>{{ getUserFullName(message) }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    Sent to:
                  </td>
                  <td v-if="isSentToProgramManger">
                    <tr>
                      <td class="p-0">
                        Program Manager
                      </td>
                    </tr>
                  </td>
                  <td v-if="isUserEmail && !isSentToProgramManger">
                    <tr
                      v-for="user in recipentUserList"
                      :key="user"
                    >
                      <td class="p-0">
                        {{ user }}
                      </td>
                    </tr>
                  </td>
                  <td v-if="isGroupEmail && !isSentToProgramManger">
                    <tr
                      v-for="groupUser in recipientGroupList"
                      :key="groupUser"
                    >
                      <td class="p-0">
                        {{ groupUser }}
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <b-button
          v-if="isGroupEmail"
          variant="outline-none"
          class="p-1"
          @click="viewSentMessageList(message.topic_id)"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="20"
          />
        </b-button>
        <small class="mail-date-time text-muted">{{ formatDate(message.created_at) }}</small>
        <!-- Mail Action DD -->
        <!-- <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="ml-50 text-body"
            />
          </template>
  
          <b-dropdown-item>
            <feather-icon icon="CornerUpLeftIcon" />
            <span class="align-middle ml-50">Reply</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </div>
    </b-card-header>
  
    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.body"
      />
      <div 
        v-if="isActiveUser(message)"
        class="d-flex flex-row-reverse"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CornerUpLeftIcon"
            size="18"
            class="mr-1"
          />
          <b-link @click="$emit('replyMessage', message)">
            Reply
          </b-link>
        </div>
      </div>
      <div 
        v-if="message.sender_id === null"
        class="d-flex flex-row-reverse font-italic"
      >
        This email was sent by an unregistered participant. Please respond to this message from your email organisation.
      </div>
      <!-- eslint-enable -->
    </b-card-body>
  </b-card>
</template>
  
<script>
import {
  BDropdown, BCard, BCardHeader, BCardBody, BAvatar, BLink, BButton
} from 'bootstrap-vue';
import { recipientTypes } from '@models';
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import { userRoles, userStatus } from '@/models';
import { isCommsBetweenProgramManagerAndParticipant, recipientHasChampionOrAdmin, senderIsChampionOrAdmin, senderIsChampionCandidateOrProgramAdminCandidate } from "@/@core/utils/utils";

export default {
  components: {
    BDropdown, BCard, BCardHeader, BCardBody, BAvatar, BLink, BButton
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    isOneToOneMessage: {
      type: Boolean,
      required: false,
    },
    recipients: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    recipentUserList() {
      return this.recipients.flatMap(receiver => receiver.topic_recipients
        .filter(t => t.recipient_type === recipientTypes.USER || t.raw_email_address)
        .flatMap(t => t.recipient_type === recipientTypes.USER && t.recipient? `${t.recipient.first_name} ${t.recipient.last_name}`: t.raw_email_address));
    },
    recipientGroupList() {
      return this.recipients.flatMap(receiver => receiver.topic_recipients
        .filter(t => t.recipient_type === recipientTypes.GROUPS)
        .flatMap(t => t.recipient.name));
    },
    isUserEmail() {
      return this.recipients.filter(r => r.topic_recipients.some(t => t.recipient_type === recipientTypes.USER )).length > 0;
    },
    isGroupEmail() {
      return this.recipients.filter(r => r.topic_recipients.some(t => t.recipient_type === recipientTypes.GROUPS )).length > 0;
    },
    recipientAll() {
      if (this.isSentToProgramManger) {
        return 'Program Manager';
      }
      return [...this.recipentUserList, ...this.recipientGroupList].filter(r => r).join(", ");
    },
    isSentToProgramManger() {
      return isCommsBetweenProgramManagerAndParticipant(this.message.topic, this.message);
    },
    programId() {
      return this.$route.params.id;
    },
    userProfileLink() {
      if (this.message?.user) {
        if (
          !senderIsChampionOrAdmin(this.message.user.user_roles) &&
          !senderIsChampionCandidateOrProgramAdminCandidate(this.message.user.user_roles)
        ) {
          return {
            name: "champion-program-participant",
            params: { id: this.programId, participantId: this.message.user.id },
          };
        }
      }
      return '';
    },
    userProfileImage() {
      return this.message.user.profile_image?.url || '';
    },
  },
  methods: {
    viewSentMessageList(id) {
      this.$router.push({
        name: "champion-program-communication-sent-messages",
        params: {
          id: this.$route.params.id,
          topicId: id
        }
      });
    },
    formatDate(date) {
      return formatDateTimeFromIsoDateTime(date, true, 'MMM DD, HH:mm');
    },
    userInitials(userName) {
      const firstInt = userName? Array.from(userName?.first_name)[0]: '';
      const lastInt = userName? Array.from(userName?.last_name)[0]: '';
      return `${firstInt}${lastInt}`;
    },
    getUserFullName(message) {
      if (message.user) {
        if (senderIsChampionOrAdmin(message.user.user_roles) && !recipientHasChampionOrAdmin(message?.topic?.topic_recipients ?? [])) {
          return "Champion";
        }
        const roles = message.user.user_roles.filter(r => {
          return [userRoles.CHAMPION_CANDIDATE, userRoles.PARTICIPANT_CANDIDATE].indexOf(r.role_id) == -1;
        });
        if (roles.length === 0) {
          return "User Removed";
        } else {
          const isActive = roles.filter(r => r.status_id === userStatus.ACTIVE).length > 0;
          if (!isActive) {
            return "User Deactivated";
          }
        }
        return `${message.user.full_name}`;
      }
      return message.sender_id !== null? "User Removed": "";
    },
    isActiveUser(message) {
      if (message.user) {
        const roles = message.user.user_roles.filter(r => {
          return [userRoles.CHAMPION_CANDIDATE, userRoles.PARTICIPANT_CANDIDATE].indexOf(r.role_id) == -1;
        });
        if (roles.length === 0) {
          return false;
        } else {
          const isActive = roles.filter(r => r.status_id === userStatus.ACTIVE).length > 0;
          if (!isActive) {
            return false;
          }
        }
        return !this.isOneToOneMessage && this.profile.id !== message.sender_id && message.sender_id !== null && message.user !== null;
      }
    },
  },
  setup() {
    return {
      recipientTypes,
      isCommsBetweenProgramManagerAndParticipant
    };
  },
};
</script>